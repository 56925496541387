body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: whitesmoke;
}

main {
    height: 45%;
    width: 45%;
    background-repeat: no-repeat;
    background-size: contain;
}
